<template>
  <div class="page-bg">
    <div class="demo-main">
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/demo' }">Demo</el-breadcrumb-item>
        <el-breadcrumb-item>Sentence speech assessment</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="title">Speech Assessment of English Sentence</div>
      <div class="sub-title">Please click the microphone and read aloud the following sentence.</div>
      <div v-if="startSoe" class="countdown">{{ second }}s</div>
      <div class="demo-container">
        <div class="ref-text-part">
          <div class="ref-text-content">
            <div class="ref-text" :class="{'ref-text-result':hasResult}">
              <span v-if="!hasResult&&!openEdit&&!hasRealtimeResult">{{ selectSentenceData }}</span>
              <i v-if="!hasResult&&!openEdit&&!hasRealtimeResult" @click="openEdit=true" class="el-icon-edit"></i>
              <el-input v-if="!hasResult&&openEdit&&!hasRealtimeResult" v-model="selectSentenceData" onkeyup="value=value.replace(/[^a-zA-Z ,.?!']/g, '')" class="editInput" placeholder="write an english sentence"></el-input>
              <i v-if="!hasResult&&openEdit&&!hasRealtimeResult" @click="selectSentenceData!==''?openEdit=false:openEdit=true" class="el-icon-check"></i>
<!--              <ul v-if="!hasResult&&hasRealtimeResult" class="sentenceResult">-->
<!--                <li v-for="(item,index) in wordInfo" :key="index">-->
<!--                  <span class="refText" :class="filter_color(item.type,item.score)">{{ item.refText }}&nbsp;</span>-->
<!--                  <span class="score" :class="filter_color(item.type,item.score)">{{ item.score.toFixed(0) }}&nbsp;</span>-->
<!--                </li>-->
<!--              </ul>-->
              <ul v-if="hasResult&&!hasRealtimeResult" class="sentenceResult">
                <li v-for="(item,index) in wordInfo" :key="index">
                  <span v-if="item.refText==='***'" class="refText" :class="filter_color(item.type,item.score)">{{ item.hypothesis }}&nbsp;</span>
                  <span v-else class="refText" :class="filter_color(item.type,item.score)">{{ item.refText }}&nbsp;</span>
                  <span class="score" :class="filter_color(item.type,item.score)">{{ item.score.toFixed(0) }}&nbsp;</span>
                </li>
              </ul>
              <ul v-if="hasResult&&!hasRealtimeResult" class="sentenceResultType">
                <li><span class="point1"></span><span>Very standard</span></li>
                <li><span class="point2"></span><span>Average</span></li>
                <li><span class="point3"></span><span>Not standard</span></li>
                <li><span class="point4"></span><span>Unknown</span></li>
                <li><span class="point5"></span><span>Unread</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="btn-recorder">
          <div @click="startEvaluation" v-if="recorderStatus === 1" class="recorder-box"><img src="~@/assets/images/soe_demo/demo/reg_start.png" alt=""></div>
          <div v-if="recorderStatus === 0" class="recorder-box recorder-box-loading">
            <ul class="loading-taste-line">
              <li class="item"></li>
              <li class="item"></li>
              <li class="item"></li>
              <li class="item"></li>
              <li class="item"></li>
            </ul>
          </div>
          <div @click="stopEvaluation" v-if="recorderStatus === 2" class="recorder-box-stop">
            <ul class="start-taste-line">
              <li class="hr1"></li>
              <li class="hr2"></li>
              <li class="hr3"></li>
              <li class="hr4"></li>
              <li class="hr5"></li>
            </ul>
            <div class="recorder-stop">
              <span></span>
            </div>
            <ul class="start-taste-line">
              <li class="hr1"></li>
              <li class="hr2"></li>
              <li class="hr3"></li>
              <li class="hr4"></li>
              <li class="hr5"></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="setting">
        <el-form ref="form" label-width="" inline>
          <el-form-item label="Evaluation group">
            <el-radio v-model="group" label="">Universal</el-radio>
            <el-radio v-model="group" label="_kids">Kids</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <el-divider v-if="hasResult"></el-divider>
      <div v-if="hasResult" class="result-detail">
        <div class="result-overall">
          <div class="text">Overall</div>
          <div class="play-replay">
            <span>{{ overall }}</span>
            <img v-if="!ifplayAudio_replay" @click="playAudio_replay" src="~@/assets/images/soe_demo/demo/play_audio.png" alt="">
            <img v-if="ifplayAudio_replay" @click="stopAudio_replay" src="~@/assets/images/soe_demo/demo/stop_audio.png" alt="">
            <audio ref="audioElement_replay" @ended="onAudioEnded_replay" :src="audioUrl"></audio>
          </div>
        </div>
        <div class="more-score">
          <div class="score-item">
            <div class="score-name">Fluency</div>
            <div class="score-value">{{ fluency }}</div>
          </div>
          <div class="score-item">
            <div class="score-name">Integrity</div>
            <div class="score-value">{{ integrity }}</div>
          </div>
          <div class="score-item">
            <div class="score-name">Accuracy</div>
            <div class="score-value">{{ accuracy }}</div>
          </div>
          <div class="score-item">
            <div class="score-name">Pause</div>
            <div class="score-value">{{ pause }}</div>
          </div>
          <div class="score-item">
            <div class="score-name">Speed</div>
            <div class="score-value">{{ speed }}</div>
          </div>
        </div>
<!--        <div class="result-part result-phoneme">-->
<!--          <div class="part-title">Phoneme-level Results</div>-->
<!--          <el-table class="phonemeTable" :data="phonemes" border>-->
<!--            <el-table-column-->
<!--                label-class-name="phonemeColumn"-->
<!--                class-name="phonemeColumnList"-->
<!--                align="center"-->
<!--                prop="phoneme"-->
<!--                label="phoneme">-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--                label-class-name="phonemeColumn"-->
<!--                class-name="phonemeColumnList"-->
<!--                align="center"-->
<!--                prop="score"-->
<!--                label="score">-->
<!--            </el-table-column>-->
<!--          </el-table>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Phoneme',
  data () {
    return {
      soe: null,

      startSoe: false,
      second: 30,
      loanTime: '',

      openEdit: false,
      selectSentenceData: "Nice to meet you.",

      group: '',

      ifplayAudio_replay: false,

      recorderStatus: 1,

      hasResult: false,
      hasRealtimeResult: false,
      wordInfo: [],
      overall: '',
      fluency: '',
      integrity: '',
      accuracy: '',
      pause: '',
      speed: '',
      audioUrl: '',
    }
  },
  mounted() {
    this.soe = new this.$SoeEngine({
      preService: this.$globalParam.preService,
      log: this.$globalParam.log,
      appId: this.$globalParam.appId,
      appSecret: this.$globalParam.appSecret,
      coreType: {
        langType: 'en-US',
        format: 'mp3', // 实时录音时可不传值；上传文件时传音频格式，支持 mp3、wav、pcm
        sampleRate: 16000, // 音频采样率（Hz）：当前仅支持 16000
        looseness: 4, // 评分宽松度，范围：0-9，数值越大越宽松
        connectTimeout: 15, // 连接超时时间（秒），范围：5-60
        responseTimeout: 15, // 响应超时时间（秒），范围：5-60
        scale: 100, // 评分分制，范围：1-100
        ratio: 1, // 评分调节系数，范围：0.8-1.5
        userId: '',
        realtime: true,
        audioUrl: true,
      },
      params: {
        mode: "", // 评测模式：基础题型 word/sentence/chapter  高阶题型 qa/topic/retell
        // 基础题型（词、句、篇章）
        refText: "", // 测评对照阅读的文本：
        // 高阶题型（问答题）
        stem: { // 题干
          para: "", // 段落内容
          question: "", // 提问内容
        },
        distractor: [],
        // 高阶题型（问答题）&& 高阶题型（看图说话/口语作文）&& 高阶题型（复述）
        answer: [],
        keyword: [],
      },
      micAllowCallback: () => {
        console.log('=====The microphone has been allowed.=====');
      },
      micForbidCallback: (msg) => {
        console.error('====='+msg+'=====');
        this.recorderStatus = 1
        this.startSoe = false
        this.$Toast(msg)
      },
      engineFirstInitDone: () => {
        console.log('=====Initialization successful.=====');
      },
      engineFirstInitFail: (status,msg) => {
        console.error('====='+status+':'+msg+'=====');
        this.recorderStatus = 1
        this.$Toast(status+':'+msg)
      },
      getStarted: () => {
        this.recorderStatus = 2
        this.loanTime = setInterval(() => {
          this.second--
          if (this.second <= 0) {
            clearInterval(this.loanTime)
            this.loanTime = ''
            this.second = 30
            this.stopEvaluation()
          }
        }, 1000)
      },
      getRealtimeResult: (msg) => {
        console.log(msg);
        // this.getRealtimeResult(msg)
      },
      engineBackResultDone: (msg) => {
        console.log(msg);
        this.recorderStatus = 1
        this.getResult(msg)
      },
      engineBackResultWarning: (status,msg) => {
        console.log(status,msg)
      },
      engineBackResultError: (status,msg) => {
        console.log(status,msg)
        this.recorderStatus = 1
        this.startSoe = false
        this.$Toast(status+':'+msg)
      },
      playAudioComplete: () => {
        console.log('Playback complete.');
      },
      playAudioError: () => {
        this.recorderStatus = 1
        this.$Toast("Playback error")
      },
      micVolumeCallback: (data) => {
        console.log('Recording volume level is:' + data);
      },
      noNetwork: () => {
        this.recorderStatus = 1
        this.$Toast("No network available")
      },
      recorderBlob: (blob) => {
        console.log(blob)
      },
    })
  },
  methods: {
    // 开始评测
    startEvaluation() {
      if (this.selectSentenceData === '') {
        return false
      }
      this.recorderStatus = 0
      this.soe.params.mode = "sentence"+this.group;
      this.soe.params.refText = this.selectSentenceData;
      let params = this.soe.params;
      this.soe.startRecord(params);
      this.hasRealtimeResult = false
      this.hasResult = false
      this.startSoe = true
    },
    // 结束评测
    stopEvaluation() {
      clearInterval(this.loanTime)
      this.loanTime = ''
      this.second = 30
      this.soe.stopRecord();
      this.startSoe = false
    },
    // 处理评测结果
    // getRealtimeResult(result) {
    //   this.wordInfo = result.wordInfo
    //   console.log(this.wordInfo)
    //   this.hasResult = false
    //   this.hasRealtimeResult = true
    // },
    getResult(result) {
      let speedNew = ''
      if (result.fluency.speed == 0) {
        speedNew = 'Slow'
      } else if (result.fluency.speed == 1) {
        speedNew = 'Normal'
      } else if (result.fluency.speed == 2) {
        speedNew = 'Fast'
      }
      this.wordInfo = result.wordInfo
      this.overall = (result.overall).toFixed(0)
      this.fluency = (result.fluency.overall).toFixed(0)
      this.integrity = (result.integrity).toFixed(0)
      this.accuracy = (result.accuracy).toFixed(0)
      this.pause = result.fluency.pause
      this.speed = speedNew

      this.audioUrl = result.audioUrl
      this.hasRealtimeResult = false
      this.hasResult = true
    },
    playAudio_replay() {
      const audioElement_replay = this.$refs.audioElement_replay;
      audioElement_replay.play();
      this.ifplayAudio_replay = true
    },
    stopAudio_replay() {
      const audioElement_replay = this.$refs.audioElement_replay;
      audioElement_replay.pause();
      audioElement_replay.currentTime = 0;
      this.ifplayAudio_replay = false
    },
    onAudioEnded_replay() {
      this.ifplayAudio_replay = false
    },

    filter_color(type,score) {
      let color = ''
      if (type === "normal") {
        if (score >= 85) {
          color = 'standard'
        } else if (score >= 60 && score < 85) {
          color = 'general'
        }else if (score < 60) {
          color = 'non'
        }
      } else if (type === "delete") {
        color = 'unread'
      } else if (type === "replace") {
        color = 'non'
      } else {
        color = 'readmore'
      }
      return color
    },
  }
}
</script>

<style scoped lang="less">
.page-bg {
  display: flex;
  padding: 30px 0;
}
.demo-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 85%;
  padding: 60px 10px 30px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 0 15px 0 hsla(0,0%,79.6%,.5);
  border-radius: 10px;
  .breadcrumb {
    position: absolute;
    left: 10px;
    top: 10px;
    margin-bottom: 30px;
  }
  .title {
    line-height: 30px;
    color: #000;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
  }
  .sub-title {
    line-height: 28px;
    margin: 15px auto 0;
    color: #333;
    text-align: center;

    font-size: 16px;
  }
  .countdown {
    position: absolute;
    right: 10px;
    top: 30px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    border-radius: 100%;
    background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
    color: #fff;
  }
  .demo-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 0 0;
    .ref-text-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0 auto;
      .ref-text {
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 26px;
        color: #333;
        .el-icon-edit {
          position: absolute;
          right: -30px;
          top: 5px;
          font-size: 24px;
          color: #2D91FA;
          cursor: pointer;
        }
        .el-icon-check {
          color: #2D91FA;
          cursor: pointer;
        }
        .editInput {
          /deep/ .el-input__inner {
            width: 100%;
            font-size: 20px;
            border-left: none;
            border-right: none;
            border-top: none;
            border-radius: inherit;
            text-align: center;
          }
        }
        .sentenceResult {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          li {
            display: flex;
            flex-direction: column;
            .score {
              text-align: center;
              font-size: 14px;
            }
          }
        }
        .sentenceResultType {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 20px;
          li {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 10px;
            font-weight: 400;
            font-size: 12px;
            color: #000;
            span:first-child {
              width: 10px;
              height: 10px;
              margin-right: 5px;
              border-radius: 100%;
            }
            .point1 {
              background: #25A926;
            }
            .point2 {
              background: #212222;
            }
            .point3 {
              background: #F90A01;
            }
            .point4 {
              background: #346DF5;
            }
            .point5 {
              background: #ACACAE;
            }
          }
        }
      }
      .ref-text-result {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .btn-try-next-box {
      margin-top: 30px;
      /deep/ .el-button--primary.is-plain {
        background: transparent;
        border-color: #2876F1;
        color: #2876F1;
        font-weight: 500;
      }
    }
    .btn-recorder {
      position: relative;
      height: 80px;
      margin-top: 23px;
      cursor: pointer;
      .recorder-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border-radius: 100%;
        background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
        box-shadow: 0 2px 6px 0 rgba(0,110,254,0.4);
        cursor: pointer;
        img {
          width: 50%;
        }
      }
      .recorder-box-loading {
        background: #DAEAFF;
        .loading-taste-line {
          position: absolute;
          width: 40px;
          height: 40px;
        }
        .item{
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          animation: xuanzhuan 4s linear infinite;
        }
        .item:nth-child(1){
          animation-delay: 0.15s;
        }
        .item:nth-child(2){
          animation-delay: 0.3s;
        }
        .item:nth-child(3){
          animation-delay: 0.45s;
        }
        .item:nth-child(4){
          animation-delay: 0.6s;
        }
        .item:nth-child(5){
          animation-delay: 0.75s;
        }
        .item::after{
          content:'';
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: royalblue;
        }
        @keyframes xuanzhuan{
          75%{
            transform: rotate(650deg);
          }
          79%{
            transform: rotate(720deg);
            opacity: 1;
          }
          80%{
            transform: rotate(720deg);
            opacity: 0;
          }
          100%{
            transform: rotate(810deg);
            opacity: 0;
          }
        }
      }
      .recorder-box-stop {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 80px;
        .recorder-stop {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 80px;
          margin: 0 10px;
          border-radius: 100%;
          background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
          box-shadow: 0 2px 6px 0 rgba(0,110,254,0.4);
          cursor: pointer;
          span {
            width: 22px;
            height: 22px;
            background: #fff;
            border-radius: 3px;
          }
        }
        .start-taste-line {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .start-taste-line li {
          background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
          width: 4px;
          height: 6px;
          margin: 0 2px;
          display: inline-block;
          border: none;
          border-radius: 1px;
        }
        li {
          animation: note 0.3s ease-in-out;
          animation-iteration-count: infinite;
          animation-direction: alternate;
        }
        .hr1 {
          animation-delay: -1s;
        }
        .hr2 {
          animation-delay: -0.9s;
        }
        .hr3 {
          animation-delay: -0.8s;
        }
        .hr4 {
          animation-delay: -0.9s;
        }
        .hr5 {
          animation-delay: -1s;
        }
        @keyframes note {
          from {
            transform: scaleY(1);
          }
          to {
            transform: scaleY(5);
          }
        }
      }
    }
  }
  .setting {
    width: 100%;
    .el-form-item {
      margin-bottom: 0;
    }
    /deep/ .el-form-item__label {
      font-weight: 500;
      font-size: 14px;
    }
    /deep/ .el-form-item__content {
      height: 40px;
      line-height: 48px;
    }
    /deep/ .el-radio {
      display: inline-flex;
      margin-right: 10px;
    }
    /deep/ .el-radio__label {
      display: inline-flex;
      padding-left: 5px;
    }
    /deep/ .el-radio__input {
      height: 14px;
    }
  }
  .result-detail {
    width: 100%;
    .result-overall {
      display: flex;
      align-items: center;
      flex-direction: column;
      .text {
        font-size: 20px;
        color: #1377e1;
        line-height: 25px;
      }
      .play-replay {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 50px;
        margin-top: 10px;
        background: #F5F9FF;
        border-radius: 36px;
        span {
          height: 30px;
          line-height: 25px;
          margin-right: 10px;
          font-weight: 500;
          font-size: 36px;
          color: #1377e1;
        }
        img {
          width: 25px;
          cursor: pointer;
        }
      }
    }
    .more-score {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 20px auto;
      .score-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        text-align: center;
        border-right: 1px solid #d2e8ff;
        .score-name {
          font-weight: 400;
          font-size: 14px;
          color: #333;
        }
        .score-value {

          font-size: 18px;
          color: #1377e1;
        }
      }
      .score-item:last-child {
        border-right: none;
      }
    }
    .result-phoneme {
      .part-title {
        padding: 30px 0;
        text-align: center;
        font-weight: 500;
        font-size: 24px;
        color: #000;
      }
      .phonemeTable {
        width: 80%;
        margin: 0 auto;
        border: 1px solid #d2e8ff;
        border-radius: 10px;
        overflow: hidden;
        /deep/ .phonemeColumnList {
          line-height: 40px;
          font-size: 18px;
          border-color: #d2e8ff;
        }
        /deep/ .phonemeColumn {
          line-height: 40px;
          font-size: 24px;
          color: #000;
          background: #F5F9FF;
          border-color: #d2e8ff;
        }
      }
    }
  }
}
</style>